<!-- Video Player for tutorial videos, video IDs are served from BatchBilling.vue in a computed property -->
<template>
  <v-dialog justify='center' style='overflow:hidden' v-if='loaded' :value="value" persistent width="1300">
    <v-card style='overflow:hidden' fluid class="fill" height="750">
      <v-row>
      <v-spacer/>
      <v-btn icon class='mx-8 mt-8' dense @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
      </v-btn>
      </v-row>
      <v-row justify="center">
      <video-player class="vjs-custom-skin" :options="playerOptions"></video-player>
      </v-row>
    </v-card>
  </v-dialog>
</template>


<script>
//npm install vue-video-player --save
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'


export default {
  name: 'VideoPlayer',

  props: ['value', 'id'],

  components:{videoPlayer},

  data () {
    return {
      loaded:false,
      playerOptions: {
        height: "640",
        sources: [
          {
            type: "video/mp4",
            src:
              ""
          }
        ],
        // techOrder: ["flash"],
        autoplay: false,
        controls: true
      }
    }
  },


  async created(){
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) {
          this.getVideo(this.id)
        }
      }
    },
  },
  methods: {
    async getVideo(id){
      await this.$Document.getFile(id).then(res=>{
        if(res.data.url){
          this.playerOptions.sources[0].src = res.data.url
          this.loaded = true
        }
      }).catch(err=>{
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Tutorial due to ${err}` })
      })
    }
  }
}
</script>
