import BaseApi from './base'
import qs from 'qs'

class Processes extends BaseApi {
  constructor () {
    super()
  }

  async getProcessList (params) {
    return this.auth({
      url: '/process',
      method: 'GET',
      params: { ...params },
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async getProcess (id) {
    return this.auth({
      url: `/process/${id}`,
      method: 'GET'
    })
  }

  async updateProcessStatus (id, params) {
    return this.auth({
      url: `/process/${id}`,
      method: 'PUT',
      params: params
    })
  }
}
export default new Processes()