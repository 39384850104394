<!-- Unused View -->
<template>
  <v-container class="archive pa-0 ma-0" fluid>
    <v-card flat class="pb-0 mb-0 pt-0">
      <v-card-text class="pb-2 mb-0 px-0 mt-0 pt-2">
        <v-data-table
          dense
          :items-per-page="pageSize"
          :headers="headers"
          :height="tableSize"
          :items="items"
          :loading="loading"
          item-key="id"
          fixed-header>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="ml-2 title">
                Billing
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { fullWidth } from '@/mixins/fullWidth'
export default {
  data () {
    return {
      items: [],
      headers: [
        {
          text: 'Example', value: 'example'
        }
      ]
    }
  },
  name: 'Billing',
  mixins: [fullWidth],
  created () {
    this.nonTableHeight = 240
  }
}
</script>
