import Vue from 'vue'
import VueRouter from 'vue-router'
import Billing from '../views/Billing.vue'
import { authGuard } from '@/auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/batch-billing',
    // name: 'Billing',
    // component: Billing,
    // meta: {
    //   requiresAuth: true,
    //   title: 'iPro | Billing'
    // }
  },
  {
    path: '/batch-billing',
    name: 'Batch',
    component: () => import('@/views/BatchBilling.vue'),
    meta: {
      requiresAuth: true,
      title: 'Batch Billing'
    }
  },
  {
    path: '/statements',
    name: 'Statement',
    component: () => import('@/views/Statements.vue'),
    meta: {
      requiresAuth: true,
      title: 'Statement Management'
    }
  },

  {
    path: '/billing_calendar',
    name: 'Billing Calendar',
    component: () => import('@/views/BillingCalendar.vue'),
    meta: {
      requiresAuth: true,
      title: 'Billing Calendar'
    }
  },

  {
    path: '/process',
    name: 'Processes',
    component: () => import('@/views/Statements.vue'),
    meta: {
      requiresAuth: true,
      title: 'Processes'
    }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Metabase.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard'
    },
    props: { id: 27 }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('@/views/Analytics.vue'),
    meta: {
      requiresAuth: true,
      title: 'Analytics'
    }
  },
  {
    path: '/resource/:id',
    name: 'Resource',
    component: () => import('@/views/Metabase.vue'),
    meta: {
      requiresAuth: true,
      title: 'iPro Billing'
    },
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  to.meta.referrer = from.name
  if (to.hasOwnProperty('meta')) {
    // Use the route's "meta.title" value to assign the page's title
    if (to.meta.title) document.title = to.meta.title
    // For routes requiring authentication ( has "meta.requiresAuth" property )
    if (to.meta.requiresAuth === true) {
      return authGuard(to, from, next)
    }
  } else {
    return next()
  }
})

export default router
