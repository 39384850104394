import Vue from 'vue'
import Vuex from 'vuex'
import Promo from '@/axios/promo'
import BillingBatch from '@/axios/billing-batch'
import Party from '@/axios/party'
import Statements from '@/axios/statements'
import KeyValues from '@/axios/key-values'
import { getInstance } from '@/auth/auth0-plugin'

Vue.use(Vuex)

const state = {
  valid: false,
  responsibleParties: [],
  promoCategories: [],
  adGroups: [],
  userRelatedParties: [],
  distributionCenters: [],
  adjustmentTypes: [],
  partyTypes: [],
  batchTypes: [],
  snackbar: {},
  resources: [
    {
      resource: 'dashboard',
      title: 'Billing Dashboard',
      id: 27,
      link: true
    },
    {
      resource: 'question',
      title: 'Batches',
      id: 132,
      link: false
    },
    {
      resource: 'question',
      title: 'Stores Missing Allowances',
      id: 133,
      link: false
    },
    {
      resource: 'question',
      title: 'Stores On Promotions',
      id: 138,
      link: false
    }
  ]
}

const getters = {
  valid (state) {
    return state.valid
  },
  resources (state) {
    return state.resources
  },
  responsibleParties (state) {
    return state.responsibleParties
  },
  distributionCenters (state) {
    return state.distributionCenters
  },
  promoCategories (state) {
    return state.promoCategories
  },
  adGroups (state) {
    return state.adGroups
  },
  userRelatedParties (state) {
    return state.userRelatedParties
  },
  adjustmentTypes (state) {
    return state.adjustmentTypes
  },
  partyTypes (state) {
    return state.partyTypes
  },
  batchTypes (state) {
    return state.batchTypes
  }
}

const mutations = {
  set_valid (userState, payload) {
    userState.valid = payload
  },
  commitValid (state, payload) {
    state.valid = payload
  },
  setResponsibleParties (state, payload) {
    state.responsibleParties = payload
  },
  setDistributionCenters (state, payload) {
    state.distributionCenters = payload
  },
  SET_SNACKBAR(state, snackbar) {
    state.snackbar = snackbar
  },
  setPromoCategories (state, payload) {
    state.promoCategories = payload
  },
  setAdGroups (state, payload) {
    state.adGroups = payload
  },
  setUserRelatedParties (state, payload) {
    state.userRelatedParties = payload
  },
  setAdjustmentTypes (state, payload) {
    state.adjustmentTypes = payload
  },
  setPartyTypes (state, payload) {
    state.partyTypes = payload
  },
  setBatchTypes (state, payload) {
    state.batchTypes = payload
  }
}

const actions = {
  async initParties (context) {
    const promises = [
      context.dispatch('getResponsibleParties'),
      context.dispatch('getAdGroups'),
      context.dispatch('getDistributionCenters')
    ]
    try {
      const results = await Promise.allSettled(promises)
      const rejected = results.filter(r => r.status === 'rejected')
      if (rejected.length > 0) throw rejected
    } catch (err) {
      console.error(err)
    }
  },
  async getResponsibleParties(context) {
    if (context.getters.responsibleParties.length > 0) return
    try {
      const res = await Party.getWholesalers()
      const parties = res?.data || []
      context.commit('setResponsibleParties', parties)
    } catch (err) {
      console.error(err)
    }
  },
  async getDistributionCenters(context) {
    try {
      const res = await Party.getDistributionCenters()
      let parties = res?.data || []
      if (parties?.length > 0) {
        parties = parties.flatMap((party) => {
          return {...party, party_id: party.id, party_name: party.name }
        })
      }
      context.commit('setDistributionCenters', parties)
    } catch (err) {
      console.error(err)
    }
  },
  setSnackbar({commit}, snackbar) {
    snackbar.showing = true
    snackbar.timeout = snackbar.status === 'success' ? 3000 : -1
    snackbar.icon = snackbar.status === 'success' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-alert'
    snackbar.color = snackbar.status === 'success' ? 'success' : 'alert'
    commit('SET_SNACKBAR', snackbar)
  },
  async getPromoCategories (context) {
    if (context.getters.promoCategories.length > 0) return
    try {
      const res = await Promo.getPromoCategories()
      const categories = res?.data || []
      context.commit('setPromoCategories', categories)
    } catch (err) {
      console.error(err)
    }
  },
  async getAdGroups (context) {
    if (context.getters.adGroups.length > 0) return
    try {
      const res = await BillingBatch.getAdGroups()
      const adGroups = res?.data || []
      context.commit('setAdGroups', adGroups)
    } catch (err) {
      console.error(err)
    }
  },
  async getUserRelatedParties (context) {
    const userParties = context.getters.userRelatedParties
    if (userParties.length > 0) return

    const authService = getInstance()
    const { email } = authService.user

    let relatedParties = []
    try {
      const res = await Party.getUserProfile(email)
      if (res?.data?.length > 0) {
        relatedParties = res.data
      }
      return context.commit('setUserRelatedParties', relatedParties)
    } catch (err) {
      console.error(err)
    }
  },
  async getAdjustmentTypes (context) {
    if (context.getters.adjustmentTypes.length > 0) return
    try {
      const res = await Statements.getAdjustmentTypeList()
      const adjustmentTypes = res?.data || []
      context.commit('setAdjustmentTypes', adjustmentTypes)
    } catch (err) {
      console.error(err)
    }
  },
  async getPartyTypes (context) {
    if (context.getters.partyTypes.length > 0) return
    let key_id = 'PARTY_TYPE'
    const params = { limit: 100, offset: 0 }
    try {
      const res = await KeyValues.getKeyValuesByKeyId(key_id, params)
      const partyTypes = res?.data || []
      context.commit('setPartyTypes', partyTypes)
    } catch (err) {
      console.error(err)
    }
  },
  async getBatchTypes (context) {
    if (context.getters.batchTypes.length > 0) return
    let key_id = 'BATCH_TYPE'
    const params = { limit: 100, offset: 0 }
    try {
      const res = await KeyValues.getKeyValuesByKeyId(key_id, params)
      const batchTypes = res?.data || []
      context.commit('setBatchTypes', batchTypes)
    } catch (err) {
      console.error(err)
    }
  }
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
})
