import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import _ from 'lodash'

//Endpoint shortcuts
import BillingBatch from '@/axios/billing-batch.js'
import document from '@/axios/document'
import Statements from './axios/statements'
import Processes from './axios/processes'
import KeyValues from '@/axios/key-values'
import Promo from '@/axios/promo'
import partyRelationship from '@/axios/party-relationship'

// Auth0
import { domain, clientId, audience } from './auth/auth0-config.js'
import { Auth0Plugin } from './auth/auth0-plugin'

// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

const appEnv = process.env.VUE_APP_ENV

if (appEnv !== 'local') {
  Sentry.init({
    Vue: Vue,
    environment: appEnv,
    dsn: 'https://4f5fdf9c3c3b4a2bb0afb0a58e305a5a@o461793.ingest.sentry.io/6009232',
    integrations: [new Integrations.BrowserTracing()],
    tracingOptions: {
      trackComponents: true
    },
    logErrors: true,
    tracesSampleRate: 1.0
  })
}


Vue.prototype.$BillingBatch = BillingBatch
Vue.prototype.$Document = document
Vue.prototype.$KeyValues = KeyValues
Vue.prototype.$PR = partyRelationship
Vue.prototype.$Promo = Promo
Vue.prototype.$Statements = Statements
Vue.prototype.$Processes = Processes
Vue.prototype._ = _

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
