import BaseApi from './base'

class KeyValues extends BaseApi {
  constructor () {
    super()
  }

  async getKeyValueByKeyId(kv_constant, params){
    return await this.auth({
      url: `/key_value_by_key_and_constant/${kv_constant}`,
      method: 'GET',
      params:params
    })
  }

  async getKeyValuesByKeyId(key_id, params){
    return await this.auth({
      url:`/key_values/${key_id}`,
      method: 'GET',
      params:params
    })
  }

  async getKeyValue(kv_id){
    return await this.auth({
      url: `/key_value/${kv_id}`,
      method: 'GET'
    })
  }
  
}

export default new KeyValues()