import axios from 'axios'
import Auth from '@/auth/auth-token'

class PartyRelationship {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.partyRelationship = base
  }

  async getMatch (party1, party2) {
    await Auth.setAuth(this.partyRelationship, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })

    return this.partyRelationship({
      url: `/party_relationship/related`,
      method: 'GET',
      params: {
        party_1_id: party1.party_1_id,
        party_1_type: party1.party_1_type,
        party_1_email: party1.party_1_email,
        party_2_id: party2.party_2_id,
        party_2_type: party2.party_2_type,
        party_2_email: party2.party_2_email
      }
    })
  }

  async getStores(){
    await Auth.setAuth(this.partyRelationship,{
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.partyRelationship({
      url: `/all_stores_for_tenant`,
      method:'GET'
    })
  }
}

export default new PartyRelationship()