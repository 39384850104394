import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Party {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_CUSTOMER_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.party = base
  }

  async getUserProfile (email, include_stores = false) {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: '/user_profile',
      method: 'GET',
      params: { email, include_stores }
    })
  }

  async getDistributionCenters () {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: '/party/search_parties',
      method: 'POST',
      data: { party_types: ['DISTRIBUTION_CENTER'] }
    })
  }

  async getWholesalers () {
    await AuthToken.setAuth(this.party, {
      audience: process.env.VUE_APP_CUSTOMER_AUDIENCE
    })
    return this.party({
      url: '/party/search_parties',
      method: 'POST',
      data: { party_types: ['WHOLESALER'] }
    })
  }
}

export default new Party()