import axios from 'axios'
import Auth from '@/auth/auth-token'

class Promo {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_PROMO_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.promo = base
  }

  async getPromoCategories() {
    await Auth.setAuth(this.promo, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })

    return this.promo({
      url: `/promo_categories`,
      method: 'GET',
    })
  }

  async getBillablePromos (billing_type, start_date, end_date, limit = 200, offset = 0) {
    await Auth.setAuth(this.promo, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })

    return this.promo({
      url: `/promo/billable_promos/${billing_type}`,
      method: 'GET',
      params: { start_date, end_date, limit, offset }
    })
  }

}

export default new Promo()