<template>
  <v-overlay v-if="$auth.isLoading || !$auth.isAuthenticated">
    <v-progress-circular
      indeterminate
      size="64">
    </v-progress-circular>
  </v-overlay>
  <v-app id="inspire" v-else>
    <v-app-bar app color="main" dark>
      <v-app-bar-nav-icon v-show="$auth.isAuthenticated" class="mx-auto" @click.stop="drawer = !drawer">
        <v-icon>{{drawer ? 'mdi-backburger' : 'mdi-menu'}}</v-icon>
      </v-app-bar-nav-icon>
        <router-link :to="{ path: '/'}">
          <v-img v-if="tenantsWithLogos.includes(`${$auth.tenant}`)"
            max-height="110"  
            max-width="110"
            class="ml-6"
            :src="require(`@/assets/${$auth.tenant}_logo.png`)" 
            :alt="`${$auth.tenant} Logo`"> 
          </v-img>
          <v-app-bar-nav-icon v-else>
            <v-icon color="#fff">mdi-home</v-icon>
          </v-app-bar-nav-icon>
        </router-link>
      <v-spacer></v-spacer>
      <!-- <v-col cols='1'> -->
      <v-menu class='pt-10' open-on-hover transition="slide-y-transition" bottom right offset-y :close-on-content-click='false'>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mr-2" v-bind="attrs" v-on="on">
            <v-icon>mdi-help-rhombus-outline</v-icon>
          </v-btn>
        </template>
        <v-card class="mx-auto" width="200" tile>
          <v-list dense>
            <v-subheader>Tutorial Videos</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(link, i) in videoLinks"
                :key='i'
                @click="openVideoPlayer(link.id)">
                {{ link.label }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
      <v-col cols="auto" class="pr-0">
        <PortalNavMenu active="billing" />
      </v-col>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" color="toolbar">
      <v-list nav>
        <v-list-item
          v-for="(link, i) in navLinks"
          :key="i"
          :to="{ path: `${link.path}` }"
          exact link>
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div :class="[(!miniVariant) ? 'pa-2': 'pa-1']" style="height:64px;">
          <v-btn block id="logoutBtn" :style="styleObject" class="mt-2" @click.prevent="logout()">
            <v-icon color="#fff">mdi-logout</v-icon>
            <span v-if="!miniVariant">Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <AlertBar></AlertBar>
      <router-view v-if="hasPortalAccess" />
      <v-alert v-else type="error" text border="left">
        You don't have authorized permissions to access this site.
      </v-alert>
      <v-snackbar multi-line bottom rounded="lg" height="60" transition="slide-y-reverse-transition"
        v-model="snackbar.showing" :timeout="snackbar.timeout" :color="snackbar.color">
        <v-row dense align="center">
          <span>
            <v-icon left>{{snackbar.icon}}</v-icon>
            {{snackbar.text}}
          </span>
        </v-row>
        <v-row dense justify="end">
          <v-btn small v-if="snackbar.status === 'error'" text @click="snackbar.showing = false">
            Close
          </v-btn>
        </v-row>
      </v-snackbar>
    </v-main>
    <v-footer color="main" dark app class="justify-space-between">
      <span class="white--text"> iPro <span class="registeredTrademark">&reg;</span> Systems, LLC. &copy; {{ new Date().getFullYear() }} </span>
      <span class="white--text">v. {{ version }}</span>
    </v-footer>
    <VideoPlayer v-if="modal" :id="id" :value.sync="modal" />
  </v-app>
</template>
<script>
const PortalNavMenu = () => import('@/components/PortalNavMenu.vue')
import AlertBar from '@/components/AlertBar.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { mapState } from 'vuex'
import { userAccess } from '@/mixins/user-access'
export default {
  data () {
    return {
      id:'',
      modal:false,
      drawer: false,
      miniVariant: false,
      styleObject: {
        background: '#E53935',
        color: 'white',
        fontSize: '15px'
      },
      navLinks: [
        {
          text: 'Batch Billing',
          path: '/batch-billing',
          icon: 'mdi-calendar-clock'
        },
        {
          text: 'Statements',
          path: '/statements',
          icon: 'mdi-note-text-outline'
        },
        // {
        //   text: 'Billing Calendar',
        //   path: '/billing_calendar',
        //   icon: 'mdi-calendar-month'
        // }

      ]
    }
  },
  name: 'App',
  components: { AlertBar, VideoPlayer, PortalNavMenu},
  mixins: [userAccess],
  watch: {
    modal: {
      handler (newValue) {
        if (newValue === false) this.id = ""
      }
    },
    tenant: {
      handler (newValue) {
        if (newValue) {
          this.$store.dispatch('initParties')
        }
      }
    }
  },
  created () {
    if (this.$auth?.tenant) {
      this.$store.dispatch('initParties')
    }
  },
  computed: {
    videoLinks(){
      return[
        { label: 'Billing Home Screen', id: '1aac8605-0db3-471c-a935-2d7b63c2f75f' },
        { label: 'Scan Billing Run', id: '8e420410-c762-4107-9c5c-e25fbdbdbb40' }
      ]
    },
    version () {
      return process.env.VUE_APP_VERSION
    },
    tenantsWithLogos () {
      return [        
        'awg',
        'alliance-retail-group'      
      ]  
    },
    ...mapState(['snackbar']),
  },
  methods: {
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    },
    openVideoPlayer (id) {
      this.id = id
      this.modal = true
    }
  }
}
</script>
<style>
  .theme--light.v-data-table .v-data-table-header th.sortable.accent.white--text .v-data-table-header__icon {
    color: #fff !important;
  }

span.registeredTrademark {
  position: relative;
  top: -.3rem;
  font-size: .5rem;
  left: -0.05rem;
}

footer {
  font-size: small;
}
</style>
