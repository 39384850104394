import BaseApi from './base'
import qs from 'qs'

class Statements extends BaseApi {
  constructor () {
    super()
  }

  async getStatementList (params) {
    return this.auth({
      url: '/statement',
      method: 'GET',
      params: { ...params },
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async getStatement (id) {
    return this.auth({
      url: `/statement/${id}`,
      method: 'GET'
    })
  }

  async postStatement (payload) {
    return this.auth({
      url: `/statement`,
      method: 'POST',
      data:payload
    })
  }

  async putStatement (id, payload) {
    return this.auth({
      url: `/statement/${id}`,
      method: 'PUT',
      data:payload
    })
  }

  async deleteStatement (id) {
    return this.auth({
      url: `/statement/${id}`,
      method: 'DELETE'
    })
  }

  async cancelStatement (id) {
    console.log('cancelling ', id)
    return this.auth({
      url: `/statement/cancel/${id}`,
      method: 'PUT'
    })
  }

  async getAdjustmentList () {
    return this.auth({
      url: '/adjustment',
      method: 'GET'
    })
  }

  async getAdjustment (id) {
    return this.auth({
      url: `/adjustment/${id}`,
      method: 'GET'
    })
  }

  async postAdjustment (payload) {
    return this.auth({
      url: `/statement/adjustment`,
      method: 'POST',
      data:payload
    })
  }

  async deleteAdjustment (id) {
    return this.auth({
      url: `/adjustment/${id}`,
      method: 'DELETE'
    })
  }

  async getAdjustmentTypeList () {
    return this.auth({
      url: '/adjustment_type',
      method: 'GET'
    })
  }

  async getAdjustmentType (id) {
    return this.auth({
      url: `/adjustment_type/${id}`,
      method: 'GET'
    })
  }

  async postAdjustmentType (payload) {
    return this.auth({
      url: `/adjustment_type`,
      method: 'POST',
      data:payload
    })
  }

  async deleteAdjustmentType (id) {
    return this.auth({
      url: `/adjustment_type/${id}`,
      method: 'DELETE'
    })
  }

  async addAdjustmentList (list){
    return this.auth({
      url:`/adjustment`,
      method: 'POST',
      data:list
    })
  }

  async getAdGroups(id) {
    return this.auth({
      url: `/adgroups_list/${id}`,
      method: 'GET'
    })
  }

  async generate(payload) {
    return this.auth({
      url:`/generate`,
      method:'POST',
      data: payload
    })
  }
  async search (params) {
    return this.auth({
      url:`/search_statement`,
      method:'GET',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async triggerStatementDocUpload(params) {
    return this.auth({
    url: `/statement_document_push`,
    method: 'POST',
    params: params
    })
  }

  async resubmitStatement(id){
    return this.auth({
      url: `/statement/rerun/${id}`,
      method: 'PUT'
    })
  }
}

export default new Statements()