export const userAccess = {
  computed: {
    entities () {
      return ['billing_batch']
    },
    adminEntities () {
      return [...this.entities, 'key', 'key_value']
    },
    tenant () {
      return this.$auth.tenant
    },
    user () {
      return this.$auth.user
    },
    userGroups () {
      return this.$auth.userGroups || []
    },
    userPermissions () {
      return this.$auth.permissions || []
    },
    userRelatedParties () {
      return this.$store.getters.userRelatedParties
    },
    userRelatedPartyIds () {
      if (this.userRelatedParties.length > 0) {
        return this.userRelatedParties.map(p => p.id)
      }
      return []
    },
    userAdGroupPartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'AD_GROUP')
          ? party.id
          : []
      })
    },
    userStorePartyIds () {
      return this.userRelatedParties.flatMap(party => {
        return (party?.party_type_constant === 'STORE')
          ? party.id
          : []
      })
    },
    userBatchPartyIds () {
      return [...this.userAdGroupPartyIds, ...this.userStorePartyIds]
    },
    hasPortalAccess () {
      const readScopes = this.entities.map(entity => `${entity}:read`)
      return readScopes.every(scope => this.userPermissions.includes(scope))
    },
    hasWriteAccess () {
      return this.writePermissions.every(scope => this.userPermissions.includes(scope))
    },
    writePermissions () {
      const actions = ['create', 'read', 'update']
      return this.entities.flatMap(entity => {
        return actions.map(action => `${entity}:${action}`)
      })
    },
    isAdmin () {
      return this.userGroups.includes('billing.admin')
        || this.adminPermissions.every(scope => this.userPermissions.includes(scope))
    },
    adminPermissions () {
      const actions = ['create', 'read', 'update', 'delete']
      return this.adminEntities.flatMap(entity => {
        return actions.map(action => `${entity}:${action}`)
      })
    },
    limitAccessByUserParties () {
      return !this.isAdmin
    },
    ad_groups () {
      return this.$store.getters.adGroups
    },
    userAdGroups () {
      let groups = [...this.ad_groups]
      if (this.limitAccessByUserParties) {
        groups = groups.filter(group => {
          return this.userAdGroupPartyIds.includes(group?.party_id)
        })
      }
      return groups
    }
  },
  methods: {
    hasScope (scope) {
      return this.userPermissions.includes(scope)
    }
  }
}