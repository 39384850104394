import BaseApi from './base'
import qs from 'qs'

class BillingBatch extends BaseApi {
  constructor () {
    super()
  }

  async getList (params) {
    return this.auth({
      url: '/billing_batches',
      method: 'GET',
      params: { ...params },
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async get (id) {
    return this.auth({
      url: `/billing_batch/${id}`,
      method: 'GET'
    })
  }

  async getByPostId (post_id) {
    return this.auth({
      url: `/billing_batch/post_id/${post_id}`,
      method: 'GET'
    })
  }

  async resubmitBatch(batch_id){
    return this.auth({
      url: `/billing_batch/rerun/${batch_id}`,
      method: 'PUT'
    })
  }


  async getAdGroups(){
    return this.auth({
      url: '/adgroups',
      method: 'GET'
    })
  }

  async post (payload) {
    return this.auth({
      url: '/billing_batch',
      method: 'POST',
      data: payload
    })
  }

  async put (id, payload) {
    return this.auth({
      url: `/billing_batch/${id}`,
      method: 'PUT',
      data: payload
    })
  }

  async delete (id) {
    return this.auth({
      url: `/billing_batch/${id}`,
      method: 'DELETE'
    })
  }

  async cancelBatch (id) {
    return this.auth({
      url: `/billing_batch/cancel/${id}`,
      method: 'PUT'
    })
  }

  async postBatches(payload) {
    return this.auth({
      url:`/billing_batch`,
      method:'PUT',
      data:payload
    })
  }

  async getBatchPromo(id, limit, offset) {
    const params = {limit:limit, offset:offset}
    return this.auth({
      url:`/promo_items/${id}`,
      method:'GET',
      params:params
    })
  }

  async getDepartmentRecaps(id, limit, offset) {
    return this.auth({
      url:`/department_recap/${id}`,
      method:'GET',
      params: { limit, offset }
    })
  }

  async getVendorRecaps(id, limit, offset) {
    return this.auth({
      url:`/vendor_recap/${id}`,
      method: 'GET',
      params: { limit, offset }
    })
  }

  async getStoreRecaps(id, limit, offset) {
    return this.auth({
      url:`/store_recap/${id}`,
      method:'GET',
      params: { limit, offset }
    })
  }

  async* generate(method, id, limit = 10000, offset = 0) {
    //called by vendor, store, and dept recap
    const request = (...args) => this[method](...args)
    let page = 1
    let elapsedTime
    const start = performance.now()
    
    while (true) {
      offset = (page * limit) - limit
      elapsedTime = performance.now() - start

      // auto-terminate if time spent retrieving all results >= 20 seconds
      if (elapsedTime >= 20000) {
        console.log('elapsed time exceeds limit, terminating')
        break
      }

      const res = await request(id, limit, offset)

      if (!res.data || res.data.length === 0) {
        break
      }
    
      yield res.data
    
      if (res.data.length < limit) break
      page++
    }
  }

  async getIneligibleRecap(id, limit, offset) {
    return this.auth({
      url:`/inel_recap/${id}`,
      method:'GET',
      params: { limit, offset }
    })
  }

  async getReversalRecap(id) {
    return this.auth({
      url: `/reversal_recaps/${id}`,
      method:'GET'
    })
  }

  async getBatchContents(id) {
    return this.auth({
      url:`/contents/${id}`,
      method:'GET',
    })
  }

  async getPostedBatches () {
    return this.auth({
      url:`/posted_batches`,
      method:'GET',
    })
  }

  async getPostedBatchesSearch (params) {
    return this.auth({
      url:`/posted_batches`,
      method:'GET',
      params: params
    })
  }

  async downloadRecaps (id) {
    return this.auth({
      url:`/download_recaps/${id}`,
      method:'GET',
    })
  }

  async previewPromos (resp_party, params){
    return this.auth({
      url:`/preview_promos/${resp_party}`,
      method:'GET',
      params:params
    })
  }

  async getBatchNotes (id){
    return this.auth({
      url: `/batch_notes/${id}`,
      method:'GET',
    })
  }

  async vendorList (id){
    return this.auth({
      url:`/vendors/${id}`,
      method:'GET',
    })
  }

  async storeList (id) {
    return this.auth({
      url:`/stores_list/${id}`,
      method:'GET',
    })
  }

  async search (params) {
    return this.auth({
      url:`/search`,
      method:'GET',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async getBatchPromos (batch_id, limit = 200, offset = 0) {
    return this.auth({
      url: `/promos_on_batch/${batch_id}`,
      method: 'GET',
      params: { limit, offset }
    })
  }

  async getBatchStores (batch_id, limit = 200, offset = 0) {
    return this.auth({
      url:`/stores/${batch_id}`,
      method:'GET',
      params: { limit, offset }
    })
  }

  async getBatchAdGroups (batch_id, limit = 200, offset = 0) {
    return this.auth({
      url: `/ad_groups/${batch_id}`,
      method: 'GET',
      params: { limit, offset }
    })
  }

  async triggerStep(batch_id, kv, params){
    return this.auth({
      url:`/trigger_step/${batch_id}`,
      method:'POST',
      params:params,
      data:kv
    })
  }

  async getPromoOverlap(params){
    return this.auth({
      url:`/check_promo_overlap`,
      method:'GET',
      params:params
    })
  }

  async billingSummariesTrigger(params){
    return this.auth({
      url:`/trigger_billing_summaries`,
      method:'POST',
      params:params
    })
  }

  async ftpPushTrigger(params){
    return this.auth({
      url:`/trigger_ftp_push`,
      method:'POST',
      params:params
    })
  }
}

export default new BillingBatch()