export const fullWidth = {
  data () {
    return {
      containerWidth: 0,
      loading: false,
      nonTableHeight: 425,
      showDialog: false,
      tableSize: 0,
      windowHeight: 0,
      pageSize: 50
    }
  },
  watch: {
    nonTableHeight: {
      handler (newValue) {
        this.tableSize = this.windowHeight - newValue
      }
    },
    windowHeight: {
      handler () {
        this.tableSize = this.windowHeight - this.nonTableHeight
      }
    }
  },
  created () {
    this.tableSize = this.windowHeight - this.nonTableHeight
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  methods: {
    resize () {
      this.windowHeight = window.innerHeight
    },
    // used by recap tables
    sumAllowance(key, value, data) {
      const allowances = data.flatMap(result => {
        return (result && result[key] === value) ? result.allowance_earned : []
      })
      const sum = allowances.reduce((total, value) => {
        return total + value
      }, 0)
      return parseFloat(sum).toFixed(2)
    }
  }
}
