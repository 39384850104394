export const displayAlert = {
  data () {
    return {
      errors: [],
      successes: [],
      messages: [],
      alert: false,
      alertType: 'warning',
      alertMessage: '',
      timeout: null
    }
  },
  watch: {
    errors: {
      handler (newValue) {
        if (newValue && newValue.length > 0) {
          this.emitAlert(true, 'warning', `Please correct the following errors: ${[...this.errors].join(', ')}`)
        }
      },
      deep: true
    },
    successes: {
      handler (newValue) {
        if (newValue && newValue.length > 0) {
          this.emitAlert(true, 'success', `Success: ${[...this.successes].join(', ')}`)
        }
      },
      deep: true
    }
  },
  methods: {
    emitAlert (showAlert, alertType, alertMessage, messages) {
      const payload = {
        alert: showAlert,
        alertType: alertType,
        alertMessage: alertMessage,
        messages: messages
      }
      this.$root.$emit('alertdialog', payload)
    },
    resetAlert () {
      this.errors.length = 0
      this.successes.length = 0
      const payload = {
        alert: false,
        alertType: 'success',
        alertMessage: '',
        messages: []
      }
      this.$root.$emit('alertdialog', payload)
    },
    handleError (err) {
      console.error(err)
      let message = err.message || 'An error has occurred'
      if (err.response && err.response.data) {
        message = `${message}. Error response data: ${JSON.stringify(err.response.data)} `
      }
      this.emitAlert(true, 'warning', message)
    }
  }
}